<template>
    <!-- 耕雲導師墨寶 -->
    <div class="law">
        <div v-for="(item,index) in list" :key="index" style="width:100%;">
            <table border="1" cellspacing="0">
                <tr class="ta-l">
                    <th colspan="4">{{item.cate_name}}</th>
                </tr>
                <tr
                    v-show="item.lists && item.lists[0].news_title"
                    v-for="(item1,index1) in getLength(item.lists)"
                    :key="index1"
                >
                    <td class="numTitle">{{index1+1}}</td>
                    <td class="leftTitle  contentTitle ta-l"  @click="linkToDetai(item.lists[index1])">{{item.lists[index1].news_title}}</td>
                    <td class="numTitle" v-show="index1+getLength(item.lists) +1">
                        <div
                       
                            v-if="item.lists[index1+getLength(item.lists)]"
                        >{{index1+getLength(item.lists) +1}}</div>
                    </td>
                    <td class="ta-l pl5">
                        <div
                        @click="linkToDetai(item.lists[index1+getLength(item.lists)])"
                        class="contentTitle"
                            v-if="item.lists[index1+getLength(item.lists)]"
                        >{{item.lists[index1+getLength(item.lists)].news_title}}</div>
                    </td>
                </tr>
            </table>
        </div>
        <div class="bottomLine"></div>
    </div>
</template>

<script>
import { getCateList, getInkList } from "../../api/apis";
export default {
    name: "",
    components: {},

    data() {
        return {
            list: [
                {
                    id: 18,
                    cate_name: "(一) 　诗歌、铭言及法语摘要",
                    lists: [
                        {
                            id: 5,
                            news_title: "安祥天使"
                        },
                        {
                            id: 4,
                            news_title: "安　祥"
                        },
                        {
                            id: 2,
                            news_title: "安祥禅简介"
                        }
                    ]
                },
                {
                    id: 19,
                    cate_name: " (二) 　耕云导师在禅宗典籍上的开示",
                    lists: []
                },
                {
                    id: 20,
                    cate_name: "(三)　 其他墨宝",
                    lists: []
                }
            ],
            length: 0
        };
    },
    methods: {
        //跳转到详情页
        linkToDetai(item){
			window.open(this.$BaseURL+'/articleDetail?name=taoist&id='+item.id);
            // this.$router.push({
            //     path: "/articleDetail",
            //     query: { name: "taoist", id: item.id }
            // });
        },
        // 获取子列表lists的长度,向上取整
        getLength(arr) {
            let length = arr.length;
            this.length = Math.ceil(length / 2);
            return Math.ceil(length / 2);
        },
        // 初始化数据，防止页面中列表渲染时出错
        initData(arr) {
            if (arr.length) {
                for (let i = 0; i < arr.length; i++) {
                    if (
                        !arr[i].lists ||
                        JSON.stringify(arr[i].lists) === "[]"
                    ) {
                        arr[i].lists = [{}];
                    }
                }
            }
            return arr;
        }
    },
    mounted() {},
    created() {
        // this.initData(this.list);
        getCateList().then(res => {
            let id = res[0][2].id;
            getInkList(id).then(res => {
                this.list = this.initData( res || []);
            });
        });
    }
};
</script>

<style lang="stylus" scoped>
.ta-l {
    text-align: left;
}

.pl5 {
    padding-left: 5px;
}

.law {
    width: 750px;
    margin: 20px 10px;

    .title {
        font-size: 15px;
        font-weight: 600;
    }
}

table, td, tr {
    border: 1px solid #ACACAC;
    border-bottom: 0px solid #ACACAC;
}

.bottomLine {
    width: 100%;
    height: 1px;
    background-color: #ACACAC;
}
table {
    width: 100%;
    font-size: 15px;

    th {
        line-height: 36px;
        font-weight: 600;
        padding-left: 15px;
    }

    td {
        line-height: 30px;
    }

    .numTitle {
        width: 80px;
    }

    .leftTitle {
        /* width: 378px; */
		width: 39%;
        padding-left: 5px;
        text-align: left;
    }
    .contentTitle:hover{
        cursor pointer;
        color:#1482d6
    }
}
</style>